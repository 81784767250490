import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Images, Overlays } from "../../assets/images/splatter_imgs";
import { Splatter } from "./Splatter";
import "./Home.css";

class Home extends Component {
  state = {
    video: "/api/files/videos/CLEAR_Video.mp4",
    callToAction: "apply", // TODO: apply, fairNotif, infoNotif

    currSession: "",
    sessions: {
      "Mentee & Mentor Kickoffs":
        "We start every year’s cohort with a session to get to know each other and begin the project brainstorming process. We also take a deep dive to explore Comcast’s end-to-end architecture to understand where we fit into the big picture.",
      "KPIs & Project Management":
        "Learn about the skills required for effective project management. This includes the usage of Key Performance Indicators (KPIs), maintaining quality communication among stakeholders, and creating realistic, yet flexible schedules. We also dive into Strategic Intellectual Property (SIP) to learn about the patenting process and what makes an idea patentable.",
      "Relationship Management":
        "After completing an assessment, you will gain an understanding of communication and behavioral styles that you naturally gravitate towards and learn how to best interact with the other styles. With this knowledge, you will then learn how to consistently achieve positive outcomes in your relationships at work and beyond.",
      "Emotional Intelligence":
        "Become more aware of your own emotional state and how those emotions impact your actions. After understanding yourself, you'll learn to identify the emotional state of those around you and how to positively manage your relationships.",
      "Presentation Skills":
        "Execute compelling presentations after discovering styles of learning, guidelines for high quality slides, tips for proper body language, and recommendations for speaking confidently in front of an audience.",
      "The Presentation Presentation & Mini Presentations":
        "Take your story telling and layouts to the next level! This session takes an even deeper dive into the mechanics of building a good slide deck and how to identify your target audience. Put what you've learned into action by performing a short presentation about your CLEAR project. Projects will be reviewed as a team and feedback will be presented in a group setting.",
      "Location Tours & Ride Alongs":
        "Here’s your chance to learn about other lines of business in Comcast. Take a tour of a Comcast location, such as a headend, data center, or an NBC studio. For extra growth, you can schedule a ride with a field technician and experience a truck roll first hand.",
      "Elevator Speeches, Personal Branding, and Panels":
        "During this session you will learn the importance of building and maintaining your personal brand. We’ll also be sitting down and having two panels to discuss Cross Functional Relationships and Leadership. This is your chance to interact directly with Comcast’s leaders and bring that insight home.",
      "Project Fair Dress Rehearsal":
        "This is the technical conclusion of your capstone project. We’ll have one more group feedback session to individually review projects and debug the presentations. Mentees will have a few more weeks for last minute changes as they get ready for the big show!",
      "Project Fair":
        "Bring together everything you have learned and showcase the work you have completed at the Project Fair. Here you will have the opportunity to spend a day sharing your project with your peers and leaders of Comcast.",
      Graduation:
        "Our last session together is a celebration of the journey. We’ll distribute your graduation plaques as a reward for all your hard work. We’ll take a moment to give thanks and reflect on what we learned and recognize those who have helped us along the way.",
    },

    projects: [
      {
        title: "ASL Visual Captions",
        description:
          "ASL (American Sign Language) Visual Captions is an accessibility feature that leverages closed caption text to deliver signed translations to the deaf and hard-of-hearing community. We want to make it easier for our ASL viewers to focus less on reading closed caption text and more time enjoying their favorite TV programs.",
        image: Images.project_1,
      },
      {
        title: "Xaid Home Care",
        description:
          "Customers are often concerned about vulnerable family members or a loved one leaving the house without notifying them. The Xaid Home Care feature provides our Xfinity Video Doorbell customers peace-of-mind with opt-in notifications when someone leaves their home. Enjoy more peace today!",
        image: Images.project_2,
      },
      {
        title: "COG",
        description:
          "No support team, no problem! Share your data when you want with whom you want and kick the spreadsheet habit. The COG platform allows you to store your data without relying on external vendors, technical resources or infrastructure. Your Data No Mess!",
        image: Images.project_3,
      },
      {
        title: "VirtuLearn",
        description:
          "Enhanced training for Technicians, in a Virtual Reality environment. VirtuLearn leverages immersive technology which intends to reduce safety issues and associated training costs. Created using Unity Game Engine for the Meta Quest 2 headset.",
        image: Images.project_4,
      },
      {
        title: "Backbone Ports Augment Streamliner",
        description:
          "We are building a tool/utility to address the following business requirements: Our current communications tend to be very vague and lead to poor confidence in the delivery of services. The tool will properly allow sales to set expectations. Identify underutilized/unused ports to complete decommissions and put ports back into our inventory allowing us to generate new monthly recurring revenues.",
        image: Images.project_5,
      },
      {
        title: "Ad Order Intake Automation",
        description:
          "Ad Order Intake automation build orders received from multiple Ad Agency in the form of email. The email order can come in multiple formats or attachments. Template Rule driven process build these orders automatically and process through Comcast Ad Delivery platform (CAD).",
        image: Images.project_6,
      },
    ],

    leaders: [
      {
        name: "Ken Klaer",
        title: "EVP of Comcast Cable, President CTS",
        description:
          "CLEAR is a fantastic program that’s focused on comprehensively nurturing and growing our engineers. Led by seasoned Comcast engineers, CLEAR offers a broad range of developmental activities and skills, culminating in a project fair where these skills are put on display. I encourage all of our employees to take advantage of the opportunity to visit this year’s virtual project fair to learn more about this valuable program and the incredible ideas that have taken shape as a result of the participants’ hard work.",
        image: Images.leader_1,
      },
      {
        name: "Rich Jennings",
        title: "President, West Division",
        description:
          "The CLEAR program has risen to the level of an “institution” for leadership development and learning. I am proud to be involved with a program that recognizes the importance of investment in our people, and helps them prepare for the next stage of their career!",
        image: Images.leader_2,
      },
      {
        name: "Larry Wolcott",
        title: "Fellow, TPX NGAN Access Eng",
        description:
          "CLEAR is a fantastic, grass-roots program that was developed to fill a gap for developing engineering talent. This isn’t just another science project, it’s a vehicle to learn important and often overlooked skills that engineers need to get ahead at Comcast. The CLEAR program continues to inspire me and so many others, thanks to so many people who volunteer, support and invest in this hidden treasure.",
        image: Images.leader_3,
      },
      {
        name: "Kevin Taylor",
        title: "Fellow II, TPX CCS Fraud",
        description:
          "CLEAR is an amazing program that helps develop the future technical leaders that Comcast and the industry need to continue to create the magnificent products and technologies that is Comcast.",
        image: Images.leader_4,
      },
      {
        name: "Charlie Herrin",
        title: "President, TPX",
        description: "Part of what makes TPX such a special place to work is our unique culture – which combines a love of innovation with a passion for winning together and a commitment to continuous learning. Programs like CLEAR not only help bring this culture to life, but they also help us shape the next generation of TPX leaders.",
        image: Images.leader_5
      },
      {
        name: "Mel Penna",
        title: "SVP, People and Integration, TPX",
        description:
          "For engineers who are interested in the management track, the importance of skill sets like project management, public speaking and networking cannot be overstated. The CLEAR Mentoring Program is a fantastic way for engineers to develop a foundation in these areas and others that can help them take their careers to the next level.",
        image: Images.leader_6,
      },
    ],
  };

  componentDidMount = () => {
    // Get width and height of the screen. Cannot use window.innerWidth because it
    // doesn't take the scrollbar into account.
    var body = document.querySelector("body");
    var splatter = new Splatter("#about-splatter", {
      width: body.clientWidth,
      height: window.innerHeight,
      maskSprites: [
        {
          count: Math.min(Math.round(body.clientWidth / 600) * 4, 18),
          url: [
            Images["splatter_hard_1"],
            Images["splatter_hard_2"],
            Images["splatter_hard_3"],
          ],
          offset: [0.75, 0.15],
          scale: [0.5, 0.5],
          rotate: [360, 0],
          opacity: 0.15,
        },
        {
          count: Math.min(Math.round(body.clientWidth / 600) * 4, 18),
          url: [
            Images["splatter_soft_1"],
            Images["splatter_soft_2"],
            Images["splatter_soft_3"],
          ],
          offset: [0.75, 0.15],
          scale: [0.65, 1],
          rotate: [360, 0],
          opacity: 0.1,
        },
        {
          count: 1,
          url: Images["splatter_layer"],
          offset: [0, 0],
          scale: 1,
          rotate: 0,
          opacity: 0.75,
        },
      ],
    });
    splatter.generate();

    /** Generate all of the splatters used in each of the sections */
    var sections = ["mission", "sessions", "projects", "leaders", "apply"];
    var overlays = Overlays;
    var splatters = [];
    var contHeight = document.getElementById("sessions-splatter").clientHeight;
    for (var i = 0; i < sections.length; i++) {
      let options = {
        width: Math.min(body.clientWidth * 1.2, 1920),
        height: Math.min(contHeight, 1080),
        texture: {
          url: Images["background_texture"],
          rotate: 0,
        },
        overlay: overlays[i],
        maskSprites: [
          {
            count: Math.min(Math.round(body.clientWidth / 300) * 2, 8),
            url: [
              Images["splatter_hard_1"],
              Images["splatter_hard_2"],
              Images["splatter_hard_3"],
            ],
            offset: body.clientWidth >= 1000 ? [0.25, 0.5] : [0.75, 0.05], // X, Y spread
            scale: body.clientWidth >= 800 ? [0.5, 0.75] : [0.5, 0.5], // Range, Min
            rotate: [360, 0], // Range, Min
            opacity: 0.3,
          },
          {
            count: Math.min(Math.round(body.clientWidth / 300) * 2, 8),
            url: [
              Images["splatter_soft_1"],
              Images["splatter_soft_2"],
              Images["splatter_soft_3"],
            ],
            offset: body.clientWidth >= 1000 ? [0.25, 0.5] : [0.75, 0.05], // X, Y spread
            scale: body.clientWidth >= 800 ? [0.65, 0.75] : [0.65, 0.5], // Range, Min
            rotate: [360, 0], // Range, Min
            opacity: 0.3,
          },
        ],
      };

      splatters[i] = new Splatter("#" + sections[i] + "-splatter", options);
      splatters[i].generate();
    }
  };

  toggleActiveGrid = (e) => {
    e.currentTarget.classList.toggle("active");
  };
  setActiveGrid = (e) => {
    e.currentTarget.classList.add("active");
  };
  unsetActiveGrid = (e) => {
    e.currentTarget.classList.remove("active");
  };

  startVideo = () => {
    var aboutVideo = document.getElementById("about-video");
    aboutVideo.classList.remove("hidden");
    aboutVideo.querySelector("video").currentTime = 0;
    aboutVideo.querySelector("video").play();
  };

  stopVideo = (e) => {
    e.stopPropagation();
    var aboutVideo = document.getElementById("about-video");
    aboutVideo.classList.add("hidden");
    aboutVideo.querySelector("video").pause();
  };

  setSession = (session) => {
    this.setState({ currSession: session });
  };

  render() {
    return (
      <main className="main">
        <section id="about" className="section">
          {/* Background & Video */}
          <div id="about-splatter" className="about-splatter"></div>
          <div
            id="about-video"
            className="about-video-container hidden"
            onClick={this.stopVideo}
          >
            <video
              src={this.state.video}
              className="about-video"
              controls
              onEnded={this.stopVideo}
              onClick={(e) => e.stopPropagation()}
            ></video>
          </div>

          {/* Section Content */}
          <div
            id="about-content"
            className="section-content about-section-text"
          >
            <h1 className="section-title about-title">
              Growing Engineers and Ideas
              <br />
              <button
                id="about-video-button"
                className="video-button"
                onClick={this.startVideo}
              >
                <svg className="icon">
                  <use xlinkHref="#icon-play_circle_outline"></use>
                </svg>
              </button>
            </h1>
          </div>

          <div className="scroll-arrow-text">
            <span>
              <small>Scroll Down</small>
            </span>
          </div>
          <div className="scroll-arrow">
            <FontAwesomeIcon className="icon" icon={faChevronDown} />
          </div>
        </section>

        <span id="mission"></span>
        <section className="section">
          <div className="section-content">
            <h1 className="section-title">Mission</h1>
            <p>
              “Growing Engineers, Ideas, and Relationships”
            </p>
            <p>
              CLEAR is a mentorship program intended to develop mature, well-rounded technical careers, and to grow technical leadership within Comcast. 
              We believe in creating and sharing professional networks, providing mentorship opportunities, and instilling the value of relationships in the future leaders of Comcast.
            </p>
            <p>
              The vision of the CLEAR program is to Grow Engineers and Ideas across the Comcast organization through mentoring relationships. 
              CLEAR provides opportunities for less-experienced engineers to work on a cross-functional project under the mentorship of more-experienced engineers. 
              This collaboration allows engineers of the highest caliber to work with our developing engineering talent. 
              Relationships are cultivated that cross organizational boundaries as Comcast employees work together to solve problems and create new products and services in the form of a Mentee Capstone Project.
            </p>
          </div>

          <div id="mission-splatter" className="section-image">
            <blockquote className="section-quote">
              <span className="section-author"></span>
            </blockquote>
          </div>
        </section>

        <span id="sessions"></span>
        <section className="section">
          <div className="section-content">
            <h1 className="section-title">Sessions</h1>
            <dl className="accordion">
              {Object.keys(this.state.sessions).map((term, i) => {
                return (
                  <React.Fragment key={i}>
                    <dt onClick={() => this.setSession(term)}>
                      <FontAwesomeIcon
                        className="icon"
                        icon={
                          this.state.currSession === term
                            ? faChevronDown
                            : faChevronRight
                        }
                      />
                      <span className="accordion-term">{term}</span>
                    </dt>
                    <dd
                      className={
                        this.state.currSession === term
                          ? "accordion-descr"
                          : "collapse"
                      }
                    >
                      {this.state.sessions[term]}
                    </dd>
                  </React.Fragment>
                );
              })}
            </dl>
          </div>
          <div id="sessions-splatter" className="section-image">
            <blockquote className="section-quote">
              You may have the best idea in the world, but if you can’t
              communicate your idea to other people and get them excited and
              passionate about it, the idea may as well be useless.
              <span className="section-author"></span>
            </blockquote>
          </div>
        </section>

        <span id="projects"></span>
        <section className="section">
          <div className="section-content">
            <h1 className="section-title">Projects</h1>
            <p>
              Throughout the program, Mentees will have an opportunity to work
              on a project of their choice. Projects are designed to improve
              visibility into other departments, expand one's professional
              network, and complete a cross-functional initiative. Here are a
              few examples of past CLEAR projects.
            </p>
            <ul className="grid-list">
              {this.state.projects.map((p, i) => {
                return (
                  <li
                    key={i}
                    className="grid-list-item"
                    onClick={this.toggleActiveGrid}
                    onMouseEnter={this.setActiveGrid}
                    onMouseLeave={this.unsetActiveGrid}
                  >
                    <span className="grid-list-descr">
                      <div className="centered-scroll-fix">
                        <div className="grid-list-title">{p.title}</div>
                        <div className="grid-list-text">{p.description}</div>
                      </div>
                    </span>
                    <img
                      src={p.image}
                      alt={p.title}
                      className="grid-list-image"
                    />
                  </li>
                );
              })}
            </ul>
          </div>

          <div id="projects-splatter" className="section-image">
            <blockquote className="section-quote">
              At the end of the year, Mentees will have a chance to show their
              projects to their colleagues and to senior leadership.
            </blockquote>
          </div>
        </section>

        <span id="leaders"></span>
        <section className="section leader-section">
          <div className="section-content">
            <h1 className="section-title">Leaders</h1>
            <ul className="grid-list">
              {this.state.leaders.map((p, i) => {
                return (
                  <li
                    key={i}
                    className="grid-list-item"
                    onClick={this.toggleActiveGrid}
                    onMouseEnter={this.setActiveGrid}
                    onMouseLeave={this.unsetActiveGrid}
                  >
                    <span className="grid-list-descr">
                      <div className="centered-scroll-fix">
                        <div className="grid-list-title">{p.name}</div>
                        <div className="grid-list-subtitle">{p.title}</div>
                        <div className="grid-list-text">{p.description}</div>
                      </div>
                    </span>
                    <img
                      src={p.image}
                      alt={p.title}
                      className="grid-list-image"
                    />
                  </li>
                );
              })}
            </ul>
          </div>

          <div id="leaders-splatter" className="section-image">
            <blockquote className="section-quote">
              The CLEAR Program enjoys support from across the organization.
              You'll have opportunities to interact with some of the best
              leaders in the company!
            </blockquote>
          </div>
        </section>

        <span id="apply"></span>
        <section className="section">
          <div className="section-content">
            <h1 className="section-title">Join Us!</h1>

            {this.state.callToAction === "apply" ? (
              <p>
                Interested in applying? To get all the details, begin the
                application process using the link below!
              </p>
            ) : this.state.callToAction === "fairNotif" ? (
              <p>
                The application window for this year is closed, but be sure to
                attend the <a href="/project-fair/2024">Project Fair</a> in
                December and check back in February for our info sessions for
                next year!
              </p>
            ) : this.state.callToAction === "infoNotif" ? (
              <p>
                The application window for the next year is not yet open, but be
                sure to keep an eye out for our info sessions in January!
              </p>
            ) : (
              ""
            )}

            <p>
              If you have any questions or comments, please reach out to us
              through{" "}
              {/*<a href="https://teams.microsoft.com/l/channel/19%3ac5b4e54bfa634489b9226cc26b648f3e%40thread.skype/General?groupId=42b2f4f4-6fa2-47dc-b3a6-3d6023bb56e9&tenantId=906aefe9-76a7-4f65-b82d-5ec20775d5aa">
                Teams
              </a> 
              , */}{" "}
              <a href="https://app.slack.com/client/T024VU91V/CGKTPBYKG">
                Slack
              </a>
              , or by Email: <a href="clear@comcast.com">clear@comcast.com</a>{" "}
            </p>

            <div className="apply-actions">
              {this.state.callToAction === "---fairNotif" ||
              this.state.callToAction === "xxx---infoNotif" ? (
                <div className="apply-form">
                  <input
                    className="apply-input"
                    type="text"
                    placeholder="Email address..."
                  />
                  <button className="btn apply-button" href="">
                    Get Notified
                  </button>
                </div>
              ) : this.state.callToAction === "apply" ? (
                <a href="/apply" className="btn apply-button">
                  Apply Now
                </a>
              ) : null}
            </div>
          </div>

          <div id="apply-splatter" className="section-image">
            <blockquote className="section-quote">
              In CLEAR, my network is your network. Find out how you can
              challenge yourself to grow and see different parts of the company
              by joining us!
            </blockquote>
          </div>
        </section>
      </main>
    );
  }
}

export default Home;
