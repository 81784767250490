import { useRef, useState } from "react"
import { Card } from "react-bootstrap"
import classNames from "../../../util/classNames"
import './IntroVideoCard.css'

const video = "/api/files/videos/Info Session (2025).mp4"

export const IntroVideoCard = () => {
  const [showVideo, setShowVideo] = useState(false)
  const videoRef = useRef()
  const startVideo=()=>{
    if (showVideo) return
    setShowVideo(true)
    if (!videoRef) return
    videoRef.current.currentTime = 0
    videoRef.current.play()
  }
  const stopVideo=()=>{
    if (!showVideo) return
    setShowVideo(false)
    if (!videoRef) return
    videoRef.current.pause()
  }

  return (
    <Card className='intro-video-card'>
      <Card.Body>
        <Card.Title>Mentorship Info Session</Card.Title>
        <Card.Text className='card-date'>Jan 24, 2025</Card.Text>
        <Card.Text>
          This hour long session covers what CLEAR is, the program objectives, and
          what CLEAR might mean for you.
        </Card.Text>
      </Card.Body>
      <div className='video-wrapper'>
        <div>
          <video src={video} ref={videoRef} className={classNames('', {hidden:!showVideo})}
            controls
            onEnded={stopVideo}
          />
          <button className={classNames('video-button', {hidden:showVideo})} onClick={startVideo}>
            <svg className="icon">
              <use xlinkHref="#icon-play_circle_outline"></use>
            </svg>
          </button>
        </div>
      </div>
    </Card>
  )
}
